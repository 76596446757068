import React, { useEffect, useState } from 'react';
import './Header.css';
import image from '../../assets/flavify.svg';
import userimage from '../../assets/user.svg';
import discount from '../../assets/discount.svg';
import logout from '../../assets/logout.svg';
import deleteoutline from '../../assets/deleteoutline.svg'; // Ensure this import if used
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchMenu } from '../MenuItem/Menuslice/menuslice';
import { Dropdown, DropdownDivider } from 'react-bootstrap';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isOnAvailability, setIsOnAvailability] = useState(false);
    const dispatch = useDispatch();
    const handleMenuOption = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const handleToggleAvailability = () => {
        setIsOnAvailability(prevState => !prevState);
    };

    useEffect(() => {
        dispatch(fetchMenu());
      }, [0]);

    return (
        <div className='header'>
            <div className="logo">
                <img
                    src={image}
                    alt="Flavify Logo"
                />
                <h4>Flavify</h4>
            </div>
            <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                     <div className="user">
                        <img src={userimage} alt="User Icon"  />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className='menu-header'>
                    <Link to={'#'}><img
                        src={discount}
                        alt=""
                    />
                        Max discount
                        </Link>
                        <DropdownDivider />
                    <Link to={'#'}><img
                        src={logout}
                        alt=""
                    />
                        Logout  
                    </Link>
                </Dropdown.Menu>
            </Dropdown>
            
        </div>
    );
}

export default Header;
