import React from 'react'
import './Orders.css'
import MainContent from '../MainContent/MainContent';
import MenuItem from '../MenuItem/MenuItem'

function Orders({ path }) {
    const render = () => {
        switch (path) {
            case '/':
                return <div><MainContent /></div>;
            case '/manage':
                return <div><MenuItem /></div>;
            default:
                return <><MainContent /></>
        }
    }
    return (
        <>
            {render()}
        </>
    )
}

export default Orders