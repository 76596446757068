import { configureStore } from "@reduxjs/toolkit";
import tableReducer from '../Component/MainContent/MainContentSlice';
import menureducer from '../Component/MenuItem/Menuslice/menuslice'
const store = configureStore({
    reducer:{
     table: tableReducer,
     food: menureducer
    }
})

export default store;