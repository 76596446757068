const PrintKOT = ({ data, tableNo, menu }) => {
    console.log({ data });
    return (
        <div
        id="printKot"
        style={{
          fontFamily: "Arial, sans-serif",
          width: "275px",
          margin: "20px auto",
          background: "white",
          padding: "20px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <h1>{tableNo}</h1>
          <h2>RYBA Cafe & Dining</h2>
          <div>Item Report - 01-08-2024</div>
        </div>

        <table style={{width: '100%'}}>
          <caption>Order Summary</caption>
          <thead>
            <tr>
            <th style={{textAlign:'start'}}>sr.no</th><th style={{textAlign:'start'}}>Item</th> <th style={{textAlign:'end'}}>Qty</th>
            </tr>
          </thead>
          <tbody>
              {data.map((item, index) => 
              item.items.map((subItem, subIndex) => ["None", "Checkout"].includes(subItem.combo) ? (
                <>
                  <tr key={`${index}-${subIndex}`}>
                      <td><b>{subIndex + 1}</b></td>
                      <td>
                        <b>{subItem?.items[0]?.item_name}</b>
                        {subItem?.items[0]?.add_ons?.length > 0 && <div className="OrderDetailsSubInfo">
                            <h6 style={{marginBottom: '2px', fontSize: "14px"}}>AddOn:</h6> {subItem?.items[0]?.add_ons?.map((i, inx) => (
                                <p style={{marginBottom: '2px', fontSize: "12px"}}>

                                    <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                    <span> - Price: {i.price}</span>
                                </p>
                            ))}
                            </div>}
                        {Object.keys(subItem?.items[0]?.options).length > 0 && <div className="OrderDetailsSubInfo">
                        <h6 style={{marginBottom: '2px', fontSize: "14px"}}>Options:</h6> {subItem?.items[0]?.options && Object.entries(subItem.items[0].options).map(([optionName, optionDetails], idx) => (
                            <p  style={{marginBottom: '2px', fontSize: "12px"}} key={idx}>
                                <span>{optionName}:</span> 
                                <span>{menu?.options?.find(opt => opt.option_id === optionDetails.option_id)?.option_name}</span>
                                <span> - Price: {optionDetails.price}</span>
                            </p>
                            ))}
                        </div>}
                      </td>
                      <td style={{textAlign:'end'}}>{subItem.qty}</td> 
                  </tr>
                  <tr>
                    <td colSpan={4}><hr/></td>
                  </tr>
                </>
              ) : (
                <>
                  <tr key={`${index}-${subIndex}`}>
                        <td><b>{subIndex + 1}</b></td>
                      <td>
                          {/* <b>{subItem.items.map((i) => i.item_name).join(" + ")}</b> */}
                          {subItem?.items?.map((i,ix) => (
                            <>
                            <b>{i.item_name}</b>
                            {i?.add_ons?.length > 0 && <div className="OrderDetailsSubInfo">
                            <h6 style={{marginBottom: '2px', fontSize: "14px"}}>AddOn:</h6> {i?.add_ons?.map((i, inx) => (
                                <p style={{marginBottom: '2px', fontSize: "12px"}}>

                                    <span>{menu?.addOns?.find(j => j.addon_id === i.addon_id)?.addon_name}</span>
                                    <span> - Price: {i.price}</span>
                                </p>
                            ))}
                            </div>}
                        {Object.keys(i?.options).length > 0 && <div className="OrderDetailsSubInfo">
                        <h6 style={{marginBottom: '2px', fontSize: "14px"}}>Options:</h6> {i?.options?.map((i, idx) => (
                            <p  style={{marginBottom: '2px', fontSize: "12px"}} key={idx}>
                                <span>{menu?.options?.find(opt => opt.option_id === i.option_id)?.option_name}</span>
                                <span> - Price: {i.price}</span>
                            </p>
                            ))}
                        </div>}
                            </>
                          )) }
                          
                      </td>
                      <td style={{textAlign:'end'}}>{subItem.qty}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}><hr/></td>
                  </tr>
                </>
              )))}
          </tbody>
        </table>
      </div>
    );
};


  export default PrintKOT