// ablyClient.js
import Ably from "ably";

let ablyClient;

export const getAblyClient = () => {
  if (!ablyClient) {
    ablyClient = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
  }
  return ablyClient;
};
