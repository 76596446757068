// AblyContext.js
import React, { createContext, useContext, useMemo } from "react";
import { getAblyClient } from "./ablyClient";

// Create a context for the Ably client
const AblyContext = createContext(null);

// Define the provider component
export const AblyProvider = ({ children }) => {
  const client = useMemo(() => getAblyClient(), []); // Memoize the client to prevent re-creation on re-renders

  return <AblyContext.Provider value={client}>{children}</AblyContext.Provider>; // Provide the client to the context
};

// Create a custom hook to access the Ably client from the context
export const useAbly = () => {
  const client = useContext(AblyContext); // Access the client from the context
  if (!client) {
    throw new Error("useAbly must be used within an AblyProvider"); // Ensure the client is available
  }
  return client; // Return the client
};
