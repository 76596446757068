import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import { Link, useLocation } from 'react-router-dom'
import support from '../../assets/support.svg'
import order from '../../assets/order.svg'
import menu from '../../assets/menu.svg'
import discount from '../../assets/discount.svg';
import logout from '../../assets/logout.svg';
import { Button, Modal } from 'react-bootstrap'
import popuplogo from '../../assets/popuplogo.svg';
import percentage from '../../assets/percentage.svg';
import Axios from '../../Helper/AxiosHelper'
import { getMaxDiscount, updateMaxDiscount } from '../MainContent/action'
import { toast } from 'react-toastify'

function Sidebar({ hadleGetPath, activePath }) {
    const [IsModal, setIsModal] = useState(false)
    const [IsPasswordModal, setIsPasswordModal] = useState(false)
    const [maxDiscount, setMaxDiscount] = useState()
    const [password, setPassword] = useState('')
    const [pathToGo, setPathToGo] = useState('')


    const handleCloseModal = () => {
        setIsModal(false);
    };
    const handleClosePasswordModal = () => {
        setPassword('')
        setIsPasswordModal(false);
    };

    useEffect(() => {
        getMaxDiscount().then((res) => {
            setMaxDiscount(res?.data?.max_discount);
        })
    }, [0])

    const handleSaveMaxDis = () => {
        try {
            const data = {
                new_discount: maxDiscount
            }
            updateMaxDiscount(data).then((res) => {
                toast.success('Max discount updated.');
                setIsModal(false)
            })
        } catch (error) {
            toast.error(error.message);
        }
    }
    const handleCheckPassword = (e) => {
        e.preventDefault() 
        if(password === '1234') {
            if(pathToGo === '/manage') {
                setIsPasswordModal(false)
                hadleGetPath(pathToGo)
                setPassword('')
            } else {
                setIsPasswordModal(false)
                setIsModal(true)
                setPassword('')
            }

        } else {
            toast.error(`wrong password! ${password}`)
        }
    }
    const onPasswordCheckOpen = (path) => {
        setIsPasswordModal(true)
        setPathToGo(path)
    }

    return (
        <div className="sidebar">
            <ul className='sidebar-menu-items'>
                <li className={`bg-green ${activePath === '/' ? 'active' : ''}`} onClick={() => hadleGetPath('/')}><Link to="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_1566_1424)">
                        <path d="M19 15V3C14.184 7.594 13.977 11.319 14 15H19ZM19 15V21H18V18M8 4V21M5 4V7C5 7.79565 5.31607 8.55871 5.87868 9.12132C6.44129 9.68393 7.20435 10 8 10C8.79565 10 9.55871 9.68393 10.1213 9.12132C10.6839 8.55871 11 7.79565 11 7V4" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1566_1424">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>Orders</Link></li>
                <li className={`bg-green ${activePath === '/manage' ? 'active' : ''}`} onClick={() => onPasswordCheckOpen('/manage')}><Link to="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_1566_1436)">
                        <path d="M17 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17C3 16.2044 3.31607 15.4413 3.87868 14.8787C4.44129 14.3161 5.20435 14 6 14H17C16.2044 14 15.4413 14.3161 14.8787 14.8787C14.3161 15.4413 14 16.2044 14 17C14 17.7956 14.3161 18.5587 14.8787 19.1213C15.4413 19.6839 16.2044 20 17 20ZM17 20H18C18.7956 20 19.5587 19.6839 20.1213 19.1213C20.6839 18.5587 21 17.7956 21 17V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H9C8.46957 4 7.96086 4.21071 7.58579 4.58579C7.21071 4.96086 7 5.46957 7 6V14" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1566_1436">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>Manage menu</Link></li>
                <li className={`bg-green ${IsModal ? 'active' : ''}`} onClick={() => onPasswordCheckOpen('/md')}>
                    <Link to={'#'}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1664_15928)">
                    <path d="M7.85547 13.25L12.8555 8.25" stroke="#4B5563" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.27214 9.08333C8.50225 9.08333 8.6888 8.89679 8.6888 8.66667C8.6888 8.43655 8.50225 8.25 8.27214 8.25C8.04202 8.25 7.85547 8.43655 7.85547 8.66667C7.85547 8.89679 8.04202 9.08333 8.27214 9.08333Z" fill="#4B5563" stroke="#4B5563" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.4362 13.2513C12.6663 13.2513 12.8529 13.0648 12.8529 12.8346C12.8529 12.6045 12.6663 12.418 12.4362 12.418C12.2061 12.418 12.0195 12.6045 12.0195 12.8346C12.0195 13.0648 12.2061 13.2513 12.4362 13.2513Z" fill="#4B5563" stroke="#4B5563" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.85547 10.75C2.85547 11.7349 3.04946 12.7102 3.42637 13.6201C3.80328 14.5301 4.35573 15.3569 5.05217 16.0533C5.74861 16.7497 6.5754 17.3022 7.48534 17.6791C8.39528 18.056 9.37055 18.25 10.3555 18.25C11.3404 18.25 12.3157 18.056 13.2256 17.6791C14.1355 17.3022 14.9623 16.7497 15.6588 16.0533C16.3552 15.3569 16.9077 14.5301 17.2846 13.6201C17.6615 12.7102 17.8555 11.7349 17.8555 10.75C17.8555 9.76509 17.6615 8.78982 17.2846 7.87987C16.9077 6.96993 16.3552 6.14314 15.6588 5.4467C14.9623 4.75026 14.1355 4.19781 13.2256 3.8209C12.3157 3.44399 11.3404 3.25 10.3555 3.25C9.37055 3.25 8.39528 3.44399 7.48534 3.8209C6.5754 4.19781 5.74861 4.75026 5.05217 5.4467C4.35573 6.14314 3.80328 6.96993 3.42637 7.87987C3.04946 8.78982 2.85547 9.76509 2.85547 10.75Z" stroke="#4B5563" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1664_15928">
                    <rect width="20" height="20" fill="white" transform="translate(0.355469 0.75)"/>
                    </clipPath>
                    </defs>
                    </svg>
                        Max discount
                    </Link>
                </li>
                {/* <li className={`bg-green`}>
                    <Link to={'#'}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.6888 3.91667C5.04447 3.91667 4.52214 4.439 4.52214 5.08333L4.52214 15.5833C4.52214 16.2277 5.04447 16.75 5.6888 16.75H10.3555C10.9998 16.75 11.5221 16.2277 11.5221 15.5833V12.6667C11.5221 12.3445 11.7833 12.0833 12.1055 12.0833C12.4276 12.0833 12.6888 12.3445 12.6888 12.6667V15.5833C12.6888 16.872 11.6441 17.9167 10.3555 17.9167H5.6888C4.40014 17.9167 3.35547 16.872 3.35547 15.5833L3.35547 5.08333C3.35547 3.79467 4.40014 2.75 5.6888 2.75L10.3555 2.75C11.6441 2.75 12.6888 3.79467 12.6888 5.08333V8C12.6888 8.32217 12.4276 8.58333 12.1055 8.58333C11.7833 8.58333 11.5221 8.32217 11.5221 8V5.08333C11.5221 4.439 10.9998 3.91667 10.3555 3.91667L5.6888 3.91667ZM9.60128 7.58752C9.82909 7.81533 9.82909 8.18467 9.60128 8.41248L8.26376 9.75L16.7721 9.75C17.0943 9.75 17.3555 10.0112 17.3555 10.3333C17.3555 10.6555 17.0943 10.9167 16.7721 10.9167L8.26376 10.9167L9.60128 12.2542C9.82909 12.482 9.82909 12.8513 9.60128 13.0791C9.37347 13.307 9.00413 13.307 8.77632 13.0791L6.44299 10.7458C6.21518 10.518 6.21518 10.1487 6.44299 9.92085L8.77632 7.58752C9.00413 7.35972 9.37348 7.35972 9.60128 7.58752Z" fill="#1F2937"/>
                    </svg>
                        Logout
                    </Link>
                </li> */}
            </ul>
            {/* <Link className='support'><img src={support} />Support</Link> */}
            <Modal
                show={IsModal}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="custom-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <img src={popuplogo} alt="popuplogo" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Set max discount throughout the website</h4>
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.</p> */}
                    <div className="input popupinput">
                        <label>Max discount (%)</label>
                        <input type='test' placeholder='e.g. 10%' value={maxDiscount} onChange={(e) => setMaxDiscount(e.target.value)}/>
                        <img src={percentage} className='input-Icon' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSaveMaxDis}>Save</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={IsPasswordModal}
                onHide={handleClosePasswordModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="custom-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Enter Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <h4>Enter Password</h4> */}
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.</p> */}
                    <form onSubmit={handleCheckPassword}>
                    <div className="input popupinput mb-3">
                        <input type='text' name='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <Button type='submit' onClick={handleCheckPassword}>Save</Button>

                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}

export default Sidebar



