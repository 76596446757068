// useAblyChannel.js
import { useEffect } from "react";
import { useAbly } from "./AblyContext";

// Create a custom hook to subscribe to an Ably channel
export function useAblyChannel(channelName, messageHandler) {
  const client = useAbly(); // Access the Ably client from the context

  useEffect(() => {
    const channel = client.channels.get(channelName); // Get the channel
    channel.subscribe(messageHandler); // Subscribe to messages

    return () => {
      channel.unsubscribe(messageHandler); // Unsubscribe from messages on cleanup
    };
  }, [client, channelName, messageHandler]); // Run effect when client, channelName, or messageHandler changes
}
