import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import star from '../../assets/star.svg';
import starblank from '../../assets/starblank.svg';
import kcal from '../../assets/kcal.svg';
import veg from '../../assets/veg.svg';
import nonveg from '../../assets/nonveg.svg';
import egg from '../../assets/egg.svg';
import popuplogo from '../../assets/popuplogo.svg';
import percentage from '../../assets/percentage.svg';
import './MenuItem.css';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setWholeMenu } from './Menuslice/menuslice';
import { getGroupedOptionsAndAddOns } from '../../Comman/Comman';
import { toggelAddon, toggelAvailable, toggelOption, toggelQuickbite } from '../MainContent/action';
import { DropdownDivider, Image } from 'react-bootstrap';
import { Icon } from '@iconify/react';

function MenuItem() {
    const dispatch = useDispatch();
    const [menuList, setMenuList] = useState(null);
    const [IsModal, setIsModal] = useState(false)
    const [item, setItem] = useState();
    const [searchTerm,setSearchTerm] = useState("");
    const {menu} = useSelector(state=>state.food)
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setItem()
      };

      useEffect(() => {
        if (menu) {
            setMenuList(menu);
        }
    }, [menu]);

    const handleCloseModal = () => {
        setIsModal(false);
    };
    const handleAvailabilityToggle = (item) => {
        const data = {
            item_id: item.item_id
        }
        toggelAvailable(data).then(res => {
            setItem((prev) => ({
                ...prev,
                is_available: !prev.is_available 
            }));
            let list = menuList
            const updatedMenuList = list?.items?.map(i => {
                if(i.item_id === item.item_id) {
                    return {...i, is_available: !i.is_available}
                }
                return i
            })

            const updatedMenu = {
                ...list,
                items: updatedMenuList
            }
            dispatch(setWholeMenu(updatedMenu))
        })
    };
    const handleQuickbiteToggle = (item) => {
        const data = {
            item_id: item.item_id
        }
        toggelQuickbite(data).then(res => {
            setItem((prev) => ({
                ...prev,
                is_quickbite: !prev.is_quickbite 
            }));
            let list = menuList
            const updatedMenuList = list?.items?.map(i => {
                if(i.item_id === item.item_id) {
                    return {...i, is_quickbite: !i.is_quickbite}
                }
                return i
            })

            const updatedMenu = {
                ...list,
                items: updatedMenuList
            }
            dispatch(setWholeMenu(updatedMenu))
        })
    };

    const handleToggleAvailabilityOption = (index, group, id) => {
        const data = {
            option_id: id
        };
    
        toggelOption(data).then(res => {
            setItem(prev => {
                let newState = { ...prev };
                console.log({ newState });
    
                const updatedData = newState?.optionsGrouped?.map(i => {
                    if (i.groupName === group) {
                        // Create a copy of the itemList
                        const updatedItemList = i.itemList.map((item, idx) => {
                            if (idx === index) {
                                // Toggle the is_available property
                                return { ...item, is_available: !item.is_available };
                            }
                            return item;
                        });
                        // Return a new object with the updated itemList
                        return { ...i, itemList: updatedItemList };
                    }
                    return i;
                });
    
                console.log({ updatedData });
                newState.optionsGrouped = updatedData;
                return newState;
            });
    
            let list = menuList;
            console.log({ menuList });
    
            const updatedMenuList = list?.options?.map(i => {
                if (i.option_id === id) {
                    return { ...i, is_available: !i.is_available };
                }
                return i;
            });
    
            const updatedMenu = {
                ...list,
                options: updatedMenuList
            };
    
            dispatch(setWholeMenu(updatedMenu));
        });
    };
    const handleToggleAvailabilityAddon = (index, group, id) => {
        const data = {
            addon_id: id
        }
        toggelAddon(data).then(res => {
            setItem(prev => {
                let newState = { ...prev };
                console.log({ newState });
    
                const updatedData = newState?.addOnsGrouped?.map(i => {
                    if (i.groupName === group) {
                        // Create a copy of the itemList
                        const updatedItemList = i.itemList.map((item, idx) => {
                            if (idx === index) {
                                // Toggle the is_available property
                                return { ...item, is_available: !item.is_available };
                            }
                            return item;
                        });
                        // Return a new object with the updated itemList
                        return { ...i, itemList: updatedItemList };
                    }
                    return i;
                });
    
                console.log({ updatedData });
                newState.addOnsGrouped = updatedData;
                return newState;
            });
            let list = menuList
            const updatedMenuList = list?.addOns?.map(i => {
                if(i.addon_id === id) {
                    return {...i, is_available: !i.is_available}
                }
                return i
            })

            const updatedMenu = {
                ...list,
                addOns: updatedMenuList
            }
            dispatch(setWholeMenu(updatedMenu))
        })
    };

    const handleShowMenu = (data) => {
        const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(menu, data.item_id);

        const itemData = {
            ...data,
            addOnsGrouped: groupedAddOns,
            optionsGrouped: groupedOptions,
        };
        setItem(itemData);
        setShow(true)
    };

    const handleDeleteItem = () => {
        console.log('delete')
    }
 
    const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

    return (
        <>
            <div className='menuitem'>
                <div className='menuitemleftright'>
                    <div className='menu-item'>
                        <h2>Menu items</h2>
                        <p>Create and edit different menu items</p>
                        <div className='search-main mb-3'>
                            <input type="text" name='serach' placeholder='Search' onChange={handleSearchChange}/>
                            <Link><Icon icon="uil:search" /></Link>
                        </div>
                        {/* <div className="settle menuitem-plus">
                            <Link className='btngreen continue' >
                                <img src={plus} alt="New item" /> New item
                            </Link>
                        </div> */}
                        <h5>Menu Controls</h5>
                        <div className="menuitemsall">
                            {menuList?.items?.filter(item =>item.item_name.toLowerCase().includes(searchTerm))?.map((item, index) => (
                                <div key={index} className="menuitems" onClick={() => handleShowMenu(item)}>
                                    <div className="menuitemsinner">
                                        <img src={item?.url} alt="Menu item"/>
                                        <div className="itemrate">
                                            <div className="rate-kcal">
                                                <div className="rate">
                                                    <img src={star} alt="Star" />
                                                    <img src={star} alt="Star" />
                                                    <img src={star} alt="Star" />
                                                    <img src={star} alt="Star" />
                                                    <img src={starblank} alt="Star blank" />
                                                </div>
                                                <div className="kcal">
                                                    <img src={kcal} alt="Kcal" />
                                                    <h4>510 kcal</h4>
                                                </div>
                                            </div>
                                            <div className="veg">
                                            <Image
                                                src={
                                                item.diet === "N"
                                                    ? nonveg
                                                    : item.diet === "V"
                                                    ? veg
                                                    : egg
                                                }
                                                alt="Veg"
                                            ></Image>
                                                {/* <img src={veg} alt="Veg" /> */}
                                            </div>
                                        </div>
                                        <div className="menu-name">
                                            <h2>{item?.item_name}</h2>
                                            <h6>₹{item?.price}</h6>
                                            <p>Lebanese Fateh Salad is a traditional Middle Eastern dish made with layers of toasted pita bread, chickpeas, and a creamy yogurt-tahini sauce.</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="menuitemfullscreen">
                    <Modal
                        id="table-order"
                        show={show}
                        onHide={handleClose}
                        className="singleitem right-to-left Productsidebarss"
                    >
                        <Modal.Header closeButton>
                        {/* <div className="table-top">
                            <div className="table-info">
                            <h3>{tabledataid.table_number}</h3>
                            <div className="modaltableinnerinfo">
                                <p>{tableOrderItem?.customer_name}</p>
                            </div>
                            </div>
                        </div> */}
                        </Modal.Header>
                        <Modal.Body>
                        {item && (
                            <div className="menu-item-fullscreen">                  
                                <img src={item?.url} alt="Full screen menu item" className='mainitemimage' />
                                <div className="menuitemfullscreeninfo">
                                    <div className="menuitem-fullscreen-left">
                                        <div className="add">
                                            <h5>{item?.item_name}</h5>
                                            <p>Customize Menu Item Visibility and Availability</p>
                                        </div>
                                        <div className="toggle-item">
                                            <h5>Toggle Availability</h5>
                                            <label className="toggle-switch">
                                                <input type="checkbox" checked={item.is_available} onChange={()=>handleAvailabilityToggle(item)} />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                        <div className="toggle-item last-item">
                                            <h5>Quickbite</h5>
                                            <label className="toggle-switch">
                                                <input type="checkbox" checked={item.is_quickbite} onChange={() => handleQuickbiteToggle(item)} />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className='adonoption-group'>
                                    <div className="menuitem-fullscreen-right">
                                        <div className="add">
                                            <h5>Add-ons</h5>
                                            <p>Create and edit different addons</p>
                                        </div>
                                        <div className="addinfo">
                                            {item?.addOnsGrouped?.map((adon, index) => (
                                                <div key={index} className="">
                                                {adon?.itemList?.map((i,ix)=>(
                                                    <div className='add-bg' key={ix}>
                                                    {console.log({i,adon})}
                                                    <div className="addmenu">                                                
                                                        <img src={addmenu} alt="Add menu" />
                                                    </div>
                                                    <div className="prize">
                                                        <label>Name</label>
                                                        <input type="text" value={i?.addon_name}/>
                                                    </div>
                                                    <div className="prize">
                                                        <label>Price</label>
                                                        <div className='inputWithIcon addprize'>
                                                            <input type="number" value={i?.price}/>
                                                            <img src={rupee} className='input-Icon' alt="Rupee icon" />
                                                        </div>
                                                    </div>
                                                    <div className="menu-container">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                <img src={editicon} alt="Toggle Menu" />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">
                                                                    <label className="toggle-switch">
                                                                        <input type="checkbox" checked={i?.is_available} onChange={() => handleToggleAvailabilityAddon(ix, adon?.groupName, i?.addon_id)} />
                                                                        <span className="slider"></span>
                                                                    </label>
                                                                    Toggle Availability
                                                                </Dropdown.Item>
                                                                <DropdownDivider />
                                                                <Dropdown.Item href="#/action-3" className='red'><Link to={'#'}><img src={deleteoutline} alt="Delete" />Delete</Link></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>                                            
                                                    </div>
                                                    </div>    
                                                ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="settle menuitem-plus greenbtn">
                                            <Link className='btngreen continue lightgreen' >
                                                <img src={plusgreen} alt="New Addon" /> Add an Addon
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuitem-fullscreen-right">
                                        <div className="add">
                                            <h5>options</h5>
                                            <p>Create and edit different options</p>
                                        </div>
                                        <div className="addinfo">
                                            {item?.optionsGrouped
                                                ?.map((option, index) => (
                                                <div key={index} className="">
                                                {option?.itemList?.map((i,ix)=>(
                                                    <div className='add-bg' key={ix}>
                                                    <div className="addmenu">                                                
                                                        <img src={addmenu} alt="Add menu" />
                                                    </div>
                                                    <div className="prize">
                                                        <label>Name</label>
                                                        <input type="text" value={i?.option_name}/>
                                                    </div>
                                                    <div className="prize">
                                                        <label>Price</label>
                                                        <div className='inputWithIcon addprize'>
                                                            <input type="number" value={i?.price}/>
                                                            <img src={rupee} className='input-Icon' alt="Rupee icon" />
                                                        </div>
                                                    </div>
                                                    <div className="menu-container">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                <img src={editicon} alt="Toggle Menu" />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">
                                                                    <label className="toggle-switch">
                                                                        <input type="checkbox" checked={i?.is_available} onChange={() => handleToggleAvailabilityOption(ix, option?.groupName ,i.option_id)} />
                                                                        <span className="slider"></span>
                                                                    </label>
                                                                    Toggle Availability
                                                                </Dropdown.Item>
                                                                <DropdownDivider />
                                                                <Dropdown.Item href="#/action-3" className='red'><Link to={'#'}><img src={deleteoutline} alt="Delete" />Delete</Link></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>                                               
                                                    </div>
                                                    </div>    
                                                ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="settle menuitem-plus greenbtn">
                                            <Link className='btngreen continue lightgreen' >
                                                <img src={plusgreen} alt="New Addon" /> Add an option
                                            </Link>
                                        </div>
                                    </div>
                                    </div> */}
                                </div>
                            </div>
                        )}
                        </Modal.Body>
                    </Modal>
                    {item && (
                    <div className="menu-item-fullscreen">                  
                        <img src={item?.url} alt="Full screen menu item" className='mainitemimage' />
                        <div className="menuitemfullscreeninfo">
                            <div className="menuitem-fullscreen-left">
                                <div className="add">
                                    <h5>{item?.item_name}</h5>
                                    <p>Customize Menu Item Visibility and Availability</p>
                                </div>
                                <div className="toggle-item">
                                    <h5>Toggle Availability</h5>
                                    <label className="toggle-switch">
                                        <input type="checkbox" checked={item.is_available} onChange={()=>handleAvailabilityToggle(item)} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="toggle-item last-item">
                                    <h5>Quickbite</h5>
                                    <label className="toggle-switch">
                                        <input type="checkbox" checked={item.is_quickbite} onChange={() => handleQuickbiteToggle(item)} />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>
                            {/* <div className='adonoption-group'>
                            <div className="menuitem-fullscreen-right">
                                <div className="add">
                                    <h5>Add-ons</h5>
                                    <p>Create and edit different addons</p>
                                </div>
                                <div className="addinfo">
                                    {item?.addOnsGrouped?.map((adon, index) => (
                                        <div key={index} className="">
                                        {adon?.itemList?.map((i,ix)=>(
                                            <div className='add-bg' key={ix}>
                                            {console.log({i,adon})}
                                            <div className="addmenu">                                                
                                                <img src={addmenu} alt="Add menu" />
                                            </div>
                                            <div className="prize">
                                                <label>Name</label>
                                                <input type="text" value={i?.addon_name}/>
                                            </div>
                                            <div className="prize">
                                                <label>Price</label>
                                                <div className='inputWithIcon addprize'>
                                                    <input type="number" value={i?.price}/>
                                                    <img src={rupee} className='input-Icon' alt="Rupee icon" />
                                                </div>
                                            </div>
                                            <div className="menu-container">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <img src={editicon} alt="Toggle Menu" />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/action-1">
                                                            <label className="toggle-switch">
                                                                <input type="checkbox" checked={i?.is_available} onChange={() => handleToggleAvailabilityAddon(ix, adon?.groupName, i?.addon_id)} />
                                                                <span className="slider"></span>
                                                            </label>
                                                            Toggle Availability
                                                        </Dropdown.Item>
                                                        <DropdownDivider />
                                                        <Dropdown.Item href="#/action-3" className='red'><Link to={'#'}><img src={deleteoutline} alt="Delete" />Delete</Link></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>                                            
                                            </div>
                                            </div>    
                                        ))}
                                        </div>
                                    ))}
                                </div>
                                <div className="settle menuitem-plus greenbtn">
                                    <Link className='btngreen continue lightgreen' >
                                        <img src={plusgreen} alt="New Addon" /> Add an Addon
                                    </Link>
                                </div>
                            </div>
                            <div className="menuitem-fullscreen-right">
                                <div className="add">
                                    <h5>options</h5>
                                    <p>Create and edit different options</p>
                                </div>
                                <div className="addinfo">
                                    {item?.optionsGrouped
                                        ?.map((option, index) => (
                                        <div key={index} className="">
                                        {option?.itemList?.map((i,ix)=>(
                                            <div className='add-bg' key={ix}>
                                            <div className="addmenu">                                                
                                                <img src={addmenu} alt="Add menu" />
                                            </div>
                                            <div className="prize">
                                                <label>Name</label>
                                                <input type="text" value={i?.option_name}/>
                                            </div>
                                            <div className="prize">
                                                <label>Price</label>
                                                <div className='inputWithIcon addprize'>
                                                    <input type="number" value={i?.price}/>
                                                    <img src={rupee} className='input-Icon' alt="Rupee icon" />
                                                </div>
                                            </div>
                                            <div className="menu-container">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <img src={editicon} alt="Toggle Menu" />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/action-1">
                                                            <label className="toggle-switch">
                                                                <input type="checkbox" checked={i?.is_available} onChange={() => handleToggleAvailabilityOption(ix, option?.groupName ,i.option_id)} />
                                                                <span className="slider"></span>
                                                            </label>
                                                            Toggle Availability
                                                        </Dropdown.Item>
                                                        <DropdownDivider />
                                                        <Dropdown.Item href="#/action-3" className='red'><Link to={'#'}><img src={deleteoutline} alt="Delete" />Delete</Link></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>                                               
                                            </div>
                                            </div>    
                                        ))}
                                        </div>
                                    ))}
                                </div>
                                <div className="settle menuitem-plus greenbtn">
                                    <Link className='btngreen continue lightgreen' >
                                        <img src={plusgreen} alt="New Addon" /> Add an option
                                    </Link>
                                </div>
                            </div>
                            </div> */}
                        </div>
                        <div className="btns">
                            {/* <div className="settle menuitem-plus">
                                <Link className='btngreen continue savebtn' onClick={() => setIsModal(true)}>
                                    Save
                                </Link>
                            </div> */}
                            <div className='toggelmodal'>
                                <Modal
                                    show={IsModal}
                                    onHide={handleCloseModal}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className="custom-modal"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <img src={popuplogo} alt="popuplogo" />
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4>Set max discount throughout the website</h4>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.</p>
                                        <div className="input popupinput">
                                            <label>Max discount (%)</label>
                                            <input type='test' placeholder='e.g. 10%' />
                                            <img src={percentage} className='input-Icon' />
                                        </div>
                                    </Modal.Body>
                                    {/* <Modal.Footer>
                                        <Button onClick={handleCloseModal}>Save</Button>
                                    </Modal.Footer> */}
                                </Modal>
                            </div>
                            {/* <div className="settle menuitem-plus deleteicons">
                                <Link className='btngreen continue deletebtn' to="#" onClick={() => handleDeleteItem()}>
                                    <img src={deletered} alt="Delete" /> Delete
                                </Link>
                            </div> */}
                        </div>
                    </div>
                )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MenuItem;
