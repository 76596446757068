import Axios from "../../Helper/AxiosHelper";
const BASE_URL = process.env.REACT_APP_BASE_URL;
// get tableno
export const gettablenum = async () => {
  try {
    const response = await Axios({
      method: "get",
      url: `/admin/fetchalltables`,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};

export const orderpunch = async (data) => {
  try {
    const response = await Axios({
      method: "patch",
      url: `/admin/orderpunched`,
      data: data,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};
export const getMaxDiscount = async (data) => {
  try {
    const response = await Axios({
      method: "get",
      url: `/admin/getmaxdiscount`,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};
export const updateMaxDiscount = async (data) => {
  try {
    const response = await Axios({
      method: "put",
      url: `/admin/setmaxdiscount`,
      data: data,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};

export const ordercomplete = async (data) => {
  try {
    const response = await Axios({
      method: "patch",
      url: `/admin/ordercompleted`,
      data:data
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};
export const toggelAvailable = async (data) => {
  try {
    const response = await Axios({
      method: "patch",
      url: `/admin/toggleavailability/item`,
      data:data
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};
export const toggelQuickbite = async (data) => {
  try {
    const response = await Axios({
      method: "patch",
      url: `/admin/togglequickbite`,
      data:data
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};
export const toggelAddon = async (data) => {
  try {
    const response = await Axios({
      method: "patch",
      url: `/admin/toggleavailability/addon`,
      data:data
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};
export const toggelOption = async (data) => {
  try {
    const response = await Axios({
      method: "patch",
      url: `/admin/toggleavailability/option`,
      data:data
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};

export const gettabledata = async (id) => {
  try {
    const response = await Axios({
      method: "get",
      url: `/admin/fetchtable/${id}`,
    });
    return response.data.response; 
  } catch (error) {
    throw error; 
  }
};
export const getTablesPastOrders = async (id) => {
  try {
    const response = await Axios({
      method: "get",
      url: `/admin/orders/${id}`,
    });
    console.log({ response })
    return response.data.past_orders; 
  } catch (error) {
    throw error; 
  }
};