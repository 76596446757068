import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import Axios from "../../Helper/AxiosHelper";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchTableno = createAsyncThunk(
    'table/fetchTableno',
    async () =>{
        const response = await Axios({
            method: "get",
            url: `/admin/fetchalltables`,
          });
        return response.data.data;
    }
);
// export const fetchTableOrderItem = createAsyncThunk(
//     'table/fetchTableOrderItem',
//     async (tableId) =>{
//         const response = await axios.get(`${BASE_URL}/fetchtable/${tableId}`);
//         return response.data.response;
//     }
// );
const initialState = {
    tableno: [],
    tableOrderItem:[],
    cartItemsList: [],
    pastOrdersList: [],
  };

  const tableslice = createSlice({
    name:'table',
    initialState,
    reducers:{
        addItemToCart(state, action) {
            state.cartItemsList = action.payload
        },
        setAllPastOrders(state, action) {
            state.pastOrdersList = action.payload
        },
        setAllOrders(state, action) {
            state.tableOrderItem = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableno.fulfilled, (state, action) => {
                state.tableno = action.payload.map(i => ({...i, orderStatus: 0}));
            })
            // .addCase(fetchTableOrderItem.fulfilled, (state, action) => {
            //     state.tableOrderItem = action.payload;
            // })
    }
  });
export const {addItemToCart, setAllPastOrders,setAllOrders} = tableslice.actions;
export default tableslice.reducer;