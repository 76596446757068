import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Axios from "../../../Helper/AxiosHelper";

export const fetchQuickBites = createAsyncThunk(
  'food/fetchQuickBites',
  async () => {
    const response = await Axios({
      method: "GET",
      url: `/quickbites`,
      data:data
    });
    const data = await response.json();
    return data.data;
  }
);

export const fetchMenu = createAsyncThunk(
  'food/fetchMenu',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/menu`, {
      headers: {
          'Authorization': process.env.REACT_APP_DINER_API_KEY,
      }
    })
    console.log({ response })
  const data = await response.data;
  return data;
  }
);

const initialState = {
  quickBites: [],
  menu: [],
  categories: {},
  loading: false,
};

const foodSlice = createSlice({
  name: 'food',
  initialState,
  reducers: {
    setWholeMenu(state, action) {
      state.menu = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuickBites.fulfilled, (state, action) => {
        state.loading = false;
        state.quickBites = action.payload;
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.menu = action.payload;
        const items = action.payload.items;
        const categories = items.reduce((acc, item) => {
          if (!acc[item.item_category]) {
            acc[item.item_category] = [];
          }
          acc[item.item_category].push(item);
          return acc;
        }, {});
        state.categories = Object.keys(categories).map(item => ({
          item_name: item
        }));
      });
  },
});
export const { setWholeMenu } = foodSlice.actions;

export default foodSlice.reducer;
